import { graphql } from "gatsby";
import React, { FC } from "react";
import BlogsMain from "../components/BlogUiComponents/BlogsMain/BlogsMain";
import { UseTagsFilter } from "../components/hooks/UseTagsFilter";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";

type Props = {
  data: any;
  pageContext: any;
  location: any;
};

const BlogList: FC<Props> = ({ data, pageContext, location }) => {
  const { currentPage, techPaginationNumber, blogs } = pageContext;

  const selectedTags = UseTagsFilter(
    "tech-blog",
    blogs,
    data.allSanityTag.nodes
  );

  return (
    <div style={{ color: "white" }}>
      <Layout location={"/blog"}>
        <SEO
          title="Tech blog"
          type="website"
          description="Keep up with our latest cloud technology articles, news, and tutorials."
        />
        <>
          <BlogsMain
            blogName={"/tech-blog"}
            basePathForUrl={"/tech-blog"}
            data={data.allSanityBlog.edges}
            tags={selectedTags}
            numberPages={techPaginationNumber}
            currentPage={currentPage}
          />
        </>
      </Layout>
    </div>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query AllSanityBlogQuery($skip: Int!, $limit: Int!) {
    allSanityBlog(
      limit: $limit
      skip: $skip
      filter: { blogType: { elemMatch: { title: { eq: "tech-blog" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawBody
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          body {
            children {
              text
            }
          }
          tags {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
          publishedAt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allSanityTag {
      nodes {
        title
      }
    }
  }
`;
